import React, { useState } from 'react';
import { Card, CardBody, Form, FormGroup, Label, Input, Button, Table, Row, Col, Spinner } from 'reactstrap';
import { faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import JsonViewer from '../shared/JsonViewer';
import RecommendedBadge from '../shared/RecommendedBadge';

const IBContract = () => {
  const [contractRequestDTO, setContractRequestDTO] = useState({});
  const [contractRespDTOs, setContractRespDTOs] = useState([]);
  const [formErrors, setFormErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [copiedAlerts, setCopiedAlerts] = useState(new Map()); // Track copied alerts

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContractRequestDTO({ ...contractRequestDTO, [name]: value });
  };

  // const validateForm = () => {
  //   if (!contractRequestDTO.conId && !contractRequestDTO.symbol) {
  //     setFormErrors("Symbol must be filled.");
  //     return false;
  //   }
  //   setFormErrors("");
  //   return true;
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (!validateForm()) return;

    setIsLoading(true);
    setFormErrors(""); // Clear previous errors
    setCopiedAlerts(new Map()); // Reset all icons to faCopy on new search

    try {
      const response = await fetch('/api/contract/search', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(contractRequestDTO),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setFormErrors(errorData.message || 'An unexpected error occurred.');
        setContractRespDTOs([]);
        return;
      }

      const data = await response.json();
      setContractRespDTOs(data);
    } catch (err) {
      console.error('Failed to fetch contracts: ', err);
      setFormErrors('Failed to fetch contracts. Please try again later.');
      setContractRespDTOs([]);
    } finally {
      setIsLoading(false);
    }
  };

  const generateAlertMessage = (contract) => {
    return JSON.stringify({
      name: `${contract.localSymbol} Alert`,
      con_id: contract.conId,
      sec_type: contract.secType,
      exch: contract.exchange,
      action: 'buy',
      volume: 1
    });
  };

  const copyToClipboard = (text, contractId) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        const newCopiedAlerts = new Map();
        newCopiedAlerts.set(contractId, 'Copied');
        setCopiedAlerts(newCopiedAlerts);
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
        alert('Failed to copy text.');
      });
  };

  return (
    <Card>
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <Row>
            {/* <Col md={3}>
              <FormGroup>
                <Label for="conId">Con ID</Label>
                <Input type="number" name="conId" id="conId" onChange={handleChange} />
              </FormGroup>
            </Col> */}
            <Col md={3}>
              <FormGroup>
                <Label for="symbol">Symbol</Label>
                <Input type="text" name="symbol" id="symbol" onChange={handleChange} required/>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="secType">Security Type</Label>
                <Input type="select" name="secType" id="secType" onChange={handleChange} required>
                  <option value="">Select Security Type</option>
                  <option value="STK">STOCK</option>
                  <option value="CASH">FOREX</option>
                  {/* <option value="OPT">OPTION</option> */}
                  
                  {/* <option value="IND">INDICES</option> */}
                  <option value="FUT">FUTURES</option>
                  {/* <option value="CMDTY">COMMODITY</option>
                  <option value="FOP">FUTURES OPTION</option> */}
                </Input>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="currency">Currency</Label>
                <Input type="text" name="currency" id="currency" onChange={handleChange} />
              </FormGroup>
            </Col>
          </Row>
          {formErrors && <p className="text-danger">{formErrors}</p>}
          <Button color="primary" type="submit" disabled={isLoading}>
            {isLoading ? <Spinner size="sm" /> : 'Submit'}
          </Button>
        </Form>
      </CardBody>
      {contractRespDTOs.length > 0 && (
        <CardBody>
          <Table striped>
            <thead>
              <tr>
                <th>Con ID</th>
                <th>Long Name</th>
                <th>Local Symbol</th>
                <th>Currency</th>
                {contractRequestDTO.secType ==='FUT' && 
                <th>Last Trade Date</th>}
                <th>Exchange</th>
                <th>Alert Message</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {contractRespDTOs.map((contract, index) => {
                const alertMessage = generateAlertMessage(contract);
                const isCopied = copiedAlerts.get(index);

                return (
                  <tr key={index}>
                    <td>{contract.conId}</td>
                    <td>{contract.longName}</td>
                    <td>{contract.localSymbol}</td>
                    <td>{contract.currency}</td>
                    {contract.secType ==='FUT' && 
                    <td>{contract.lastTradeDate}</td>
                    }
                    <td>{contract.exchange}

                    {contract.exchange === 'SMART' && <>&nbsp;<RecommendedBadge index={index}/></>}

                    </td>

                    <td>
                      <JsonViewer jsonString={alertMessage}/></td>
                    <td>
                      <FontAwesomeIcon
                        icon={isCopied ? faCheck : faCopy}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          // Reset all to copy icon, then set the newly clicked to check icon
                          setCopiedAlerts(prev => new Map());
                          copyToClipboard(alertMessage, index);
                        }}
                      />
                      {isCopied && <span className="ms-2">Copied</span>}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      )}
    </Card>
  );
};

export default IBContract;
