import Moment from 'react-moment';
import { Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import JsonViewer from "../shared/JsonViewer";
import CopyButton from '../shared/CopyButton';
import StatusBadge from "../shared/StatusBadge";
import IbOrderStatusBadge from "../shared/IbOrderStatusBadge";

const AlertPopup = ({ show, onClose, message }) => {
  return (
    <>
      {message &&
        <Modal isOpen={show}>
          <ModalHeader>{message.alertName}</ModalHeader>
          <ModalBody>
            <Card color="">
              <CardBody>
                <dl>
                  <dt><span id="message">Message</span></dt>
                  <dd>
                  <div style={{ border: '1px solid lightgrey', padding: "10px" }}>
                  {message.data && <JsonViewer jsonString={message.data}/>}
                  </div>
                  <br/>
                  <CopyButton text={message.data} />
                  </dd>
                  <dt><span id="createdDate">Received Date</span></dt>
                  <dd><Moment format='DD/MM/yyyy HH:mm:ss'>{message.receivedDate}</Moment></dd>
                </dl>
                <dl>
                  <dt><span id="status">Routing Status</span></dt>
                  <dd><StatusBadge status={message.status}/> <br/>{message.error} </dd>
                </dl>
                {message.orderId &&
                  <>
                  <dl>
                    <dt><span id="status">IB Order Status</span></dt>
                    <dd><IbOrderStatusBadge status={message.ibOrderStatus} index={1}/><br/>
                      {message.ibOrderError}
                    </dd>
                  </dl>
                  <dl>
                    <dt><span id="orderId">System Order Id</span></dt>
                    <dd>{message.ibOrderId}
                    </dd>
                  </dl>
                  <dl>
                    <dt><span id="IBorderId">IB Order Id</span></dt>
                    <dd>{message.permId}
                    </dd>
                  </dl>
                  </>
                }
               
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" onClick={onClose}>Close</Button>
          </ModalFooter>
        </Modal>
      }
    </>
  );
};

export default AlertPopup;