import { UncontrolledTooltip, Badge } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const StatusBadge = ({ status, index, error=null }) => {

  let color = 'default';
  let text = status;
  switch (status) {
    case 'ROUTED':
      color = 'success';
      text = 'ROUTED';
      break;
    case 'ROUTE_FAILED':
      color = 'danger';

      break;
    case 'INVALID_JSON':
    case 'MISSING_FIELDS':
        color = 'danger';
  
        break;
    case 'UNAUTHORIZED':
      color = 'warning';
      error = 'IP is not whitelisted'
      break;
    default: 
      color = 'secondary';
      break;
  }

  return (
    <>
      {text &&
         <Badge color={color}>{text} &nbsp;
          {error && <>
                    <span id={`tooltip${index}`} style={{ cursor: 'pointer' }} ><FontAwesomeIcon icon={faInfoCircle}/></span>
                    <UncontrolledTooltip
                        placement="top"
                        target={`tooltip${index}`}
                        trigger="hover"
                    >
                        {error}
                    </UncontrolledTooltip>
                </>
                }
         </Badge>}
    </>
  )
};

export default StatusBadge;
