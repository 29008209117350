import React, { useState } from 'react';
import { Alert, Card, CardBody, CardHeader, Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import JsonViewer from '../shared/JsonViewer';
import CopyButton from '../shared/CopyButton';
import { faBookOpenReader, faCode, faInfoCircle, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const sampleData = {
  "name": "AAPL Alert",
  "con_id": 265598, // IB contract Id
  "sec_type": "STK", // STK = STOCK, CASH = FOREX
  "exch": "SMART",
  "action": "buy", // buy/sell
  "volume": 1
};

const SampleRequestCard = ({ onGoToIBContract, onGoToPlaceOrder }) => {

  const [popoverOpen, setPopoverOpen] = useState(false);

  const togglePopover = () => setPopoverOpen(!popoverOpen);

  return (

    <Card>
      <CardHeader> <FontAwesomeIcon icon={faCode}/> &nbsp;Alert Message Format</CardHeader>
      <CardBody>
        <Alert color="info">
          The alert message that is configured in TradingView must strictly follow this format. &nbsp;&nbsp;
          <Button id="popoverId" onClick={togglePopover} className="alert-link" color='link'>
            <FontAwesomeIcon icon={faInfoCircle} /> Learn More
          </Button>
          {/* <a color="info" id="popoverId" className="alert-link" onClick={togglePopover}>
            <FontAwesomeIcon icon={faInfoCircle} /> Learn More
          </a> */}
        </Alert>
        <div style={{ border: '1px solid lightgrey', padding: "10px" }} className="mb-2">
          <JsonViewer jsonString={JSON.stringify(sampleData)} />
        </div>
        <CopyButton text={JSON.stringify(sampleData)} />
        <p className="mt-3 text-muted">
          You may generate the alert message using <Button color="link" onClick={onGoToIBContract} className="alert-link">
            <FontAwesomeIcon icon={faWrench}/>Create Your Alert Message</Button>
            </p>
            <p className="mt-3 text-muted">
            You may test and validate alert message by using <Button color='link' onClick={onGoToPlaceOrder} className="alert-link">
            <FontAwesomeIcon icon={faBookOpenReader} /> Place Your Order
          </Button>
        </p>
        
          <Popover placement="right" isOpen={popoverOpen} target="popoverId" toggle={togglePopover}>
            <PopoverHeader>Alert Message Format</PopoverHeader>
            <PopoverBody>
              {/* <p><strong>Sample Alert Message:</strong></p>
              <pre>{JSON.stringify(sampleData, null, 2)}</pre>*/}
              <p><strong>Explanation:</strong></p> 
              <ul>
                <li><strong>name:</strong> The alert name for you to differentiate the alert</li>
                <li><strong>sec_type:</strong> IB securities type. Possible values:
                  <ul>
                    <li><strong>STK</strong> = STOCK</li>
                    <li><strong>CASH</strong> = FOREX</li>
                    {/* <li><strong>OPT</strong> = OPTIONS</li> */}
                    {/* <li><strong>IND</strong> = INDICES</li> */}
                    <li><strong>FUT</strong> = FUTURES</li>
                    {/* <li><strong>CMTY</strong> = COMMODITIES</li> */}
                    {/* <li><strong>FOP</strong> = FUTURES OPTION</li> */}
                  </ul>
                </li>
                <li><strong>con_id:</strong> IB contract unique key</li>
                <li><strong>exch:</strong> The exchange name</li>
                <li><strong>action:</strong> The action to take; either <strong>buy</strong> or <strong>sell</strong></li>
                <li><strong>volume:</strong> Quantity that you want to place in the order</li>
              </ul>
              <p>You may use our tool <strong>"Create Your Alert Message"</strong> to generate your alert request.</p>
            </PopoverBody>
          </Popover>
      </CardBody>
    </Card>

  );
};

export default SampleRequestCard;
