import { useState, useEffect } from 'react';
import { Outlet } from "react-router-dom";
import { Container } from 'reactstrap';
import axios from 'axios';
import Header from "./Header";
import Error from './Error';
import Footer from './Footer';

const FullLayout = () => {
  const [error, setError] = useState(null);

  const checkSession = async () => {
    try {
      const response = await axios.get('/api/session/check');
      setError(response.data);
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  useEffect(() => {
    checkSession();
  }, []);

  return (
    <main>
      <div className="pageWrapper d-lg-flex">
        {error ?
          <Error error={error} />
        :
        <>
          {/* <aside className="sidebarArea shadow" id="sidebarArea">
            <Sidebar />
          </aside> */}

          <div className="contentArea">
            <Header />
            <Container className="p-4 wrapper" fluid>
              <Outlet />
            </Container>
            <Footer/>
          </div>
        </>
        }
      </div>
    </main>
  );
};

export default FullLayout;
