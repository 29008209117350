import { Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const Popup = ({ show, onClose, message }) => {
  return (
    <>
      {message &&
        <Modal isOpen={show}>
          <ModalHeader>Message</ModalHeader>
          <ModalBody>
            <Card color="light">
              <CardBody>
                {message}
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" onClick={onClose}>Close</Button>
          </ModalFooter>
        </Modal>
      }
    </>
  );
};

export default Popup;