import React from 'react';
import { JSONTree } from 'react-json-tree';

const BrightTheme = {
  scheme: 'bright',
  base00: '#ffffff', // Background color
  base01: '#dfe6e9', // Expanded background color
  base02: '#dfe6e9', // Header background color
  base03: '#a4b0be', // Arrow color
  base04: '#55efc4', // Text color
  base05: '#0984e3', // Expanded text color
  base06: '#74b9ff', // Expanded object key color
  base07: '#0984e3', // Expanded object key background color
  base08: '#d63031', // Border color
  base09: '#fdcb6e', // Border color
  base0A: '#e84393', // Border color
  base0B: '#00b894', // Border color
  base0C: '#00cec9', // Border color
  base0D: '#0984e3', // Border color
  base0E: '#6c5ce7', // Border color
  base0F: '#fd79a8', // Border color
};

const isValidJsonString = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

const JsonViewer = ({ jsonString }) => {
  if (isValidJsonString(jsonString)) {
    const data = JSON.parse(jsonString);
    return <JSONTree data={data} theme={BrightTheme} hideRoot={true} />;
  } else {
    return (
      <div style={{ color: 'red' }}>
        <pre>{jsonString}</pre>
      </div>
    );
  }
};

export default JsonViewer;
