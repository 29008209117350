import React from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText } from 'reactstrap';

const Disclaimer = () => {
  return (
    <Container>
      <Row className="justify-content-center my-4">
        <Col md="10">
          <Card>
            <CardBody>
              <CardTitle tag="h2" className="text-danger mb-4">
                Disclaimer & User Responsibilities
              </CardTitle>
              <CardText tag="ol" className='text-muted lh-lg fs-5 text'>
                <li>
                  <strong>Xerolite as a Bridge</strong><br />
                  Xerolite acts as a bridge that receives orders through a webhook URL from TradingView and places those orders with Interactive Brokers (IB). It does not make any trading decisions on your behalf.
                </li>
                <li>
                  <strong>Ensure TWS is Active</strong><br />
                  It is the user's responsibility to ensure that their Trader Workstation (TWS) is up and running at all times when using Xerolite. If TWS is not operational, orders will not be placed, and Xerolite cannot be held responsible for missed trades.
                </li>
                <li>
                  <strong>No Guarantee of Profit</strong><br />
                  Xerolite only facilitates the process of placing orders based on your configured alerts. We do not guarantee any profits or financial gains from using this software. Trading carries inherent risks, and the results depend on your trading strategies and market conditions.
                </li>
                <li>
                  <strong>User-Generated Requests</strong><br />
                  All orders placed through Xerolite are generated by user-defined alerts and strategies. We do not influence or modify the orders. Users are fully responsible for the accuracy and correctness of the order requests they send.
                </li>
                <li>
                  <strong>Responsibility for Monitoring</strong><br />
                  Users must actively monitor their accounts, orders, and system performance. Xerolite provides tools to automate order placement, but it is the user’s responsibility to manage risk and take any corrective actions during their trading activities.
                </li>
                <li>
                  <strong>Limitation of Liability</strong><br />
                  We are not liable for any losses, delays, or failed executions due to technical issues, network failures, or misconfigurations in your alert settings, TradingView, or IB’s systems.
                </li>
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Disclaimer;
