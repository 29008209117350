import React from 'react';
import { Card, CardBody, Col, Row, CardSubtitle, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Assuming you're using Font Awesome
import { faChartPie, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const AnalyticCard = ({ color = "dark", icon=faChartPie, label, text, subtext = null, remark= null, outline=true, tooltip=null, id }) => {
    return (
        <Card color={color} inverse>
            <CardBody>
                <Row className="justify-content-center">
                <Col xs="4" className="d-flex justify-content-center align-items-center">
                        <FontAwesomeIcon icon={icon} size='3x' />
                    </Col>
                    <Col xs="8">
                        <CardSubtitle
                            className="mb-2"
                        >
                            {label} &nbsp;
                            {tooltip &&
                           <>
                                <span id={`tooltip${id}`} style={{ cursor: 'pointer' }} ><FontAwesomeIcon icon={faInfoCircle} /></span>
                                <UncontrolledTooltip
                                placement="top"
                                target={`tooltip${id}`}
                                trigger="hover"
                                >
                                    {tooltip}
                                </UncontrolledTooltip>    
                            </>
                            }
                        </CardSubtitle>
                        <h5 className="mb-1"><b>
                            {text} </b>{subtext && <span style={{fontSize: "11px"}}>{subtext}</span>}
                        </h5>
                        {remark &&
                        <CardSubtitle
                            className="text-muted"
                            tag="small"
                        >
                            {remark}
                        </CardSubtitle>
                        }
      
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

export default AnalyticCard;
