import React, { useEffect, useState, useCallback } from 'react';
import { Alert, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CopyButton from '../shared/CopyButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faRedo } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

const WebhookURL = ({ apiKey, getApiKey, loading }) => {
  const [webhookUrl, setWebhookUrl] = useState('');
  const [modal, setModal] = useState(false);

  const fetchWebhookUrl = useCallback(async () => {
    try {
      const response = await axios.get('/api/webhook/url');
      let url = response.data;

      // Check if the current hostname is an IP address or localhost
      const currentDomain = window.location.hostname;
      const isIpAddress = /^(?:\d{1,3}\.){3}\d{1,3}$/.test(currentDomain);
      const isLocalhost = currentDomain === 'localhost';

      if (!isIpAddress && !isLocalhost) {
        // Replace the IP in the URL with the current domain
        url = url.replace(/^(https?:\/\/)(\d{1,3}\.){3}\d{1,3}/, `$1${currentDomain}`);
      }

      setWebhookUrl(url + apiKey);
    } catch (error) {
      console.error('Error fetching webhook URL:', error);
    }
  }, [apiKey]);

  useEffect(() => {
    fetchWebhookUrl();
  }, [fetchWebhookUrl]); // Re-fetch the webhook URL whenever fetchWebhookUrl changes

  const toggleModal = () => setModal(!modal);

  const handleRegenerateApiKey = async () => {
    await getApiKey(true);
    toggleModal();
  };

  return (
    <Card>
      <CardHeader><FontAwesomeIcon icon={faLink} /> &nbsp;WebHook URL</CardHeader>
      <CardBody>
        <Alert color="info">
          Copy and paste this URL into TradingView webhook URL
        </Alert>
        <div style={{ border: '1px solid lightgrey', padding: "10px" }} className="mb-2">
          {webhookUrl} &nbsp;&nbsp;&nbsp;
        </div>
        <CopyButton text={webhookUrl} /> &nbsp;<Button color="warning" onClick={handleRegenerateApiKey} disabled={loading}>
          <FontAwesomeIcon icon={faRedo} /> &nbsp; Generate API Key
        </Button>
      </CardBody>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>API Key Regenerated</ModalHeader>
        <ModalBody>
          Your API key has been regenerated. Please remember to update the webhook URL in your TradingView settings.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal}>OK</Button>
        </ModalFooter>
      </Modal>
    </Card>
  );
};

export default WebhookURL;
