import React from "react";
import { Navbar, Collapse, Nav, NavItem } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from "./Logo";
import Account from "./Account";
import { faExclamationCircle, faHome, faRocket } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const navigate = useNavigate(); // Hook to navigate programmatically
  const handleHomeNavigation = () => {
    navigate('/');
  };

  return (
    <Navbar color="dark" light expand="md">
      <Collapse navbar>
        <Nav className="me-auto" navbar>
          <NavItem>
            <Logo />
            <span style={{ color: "grey" }}>
              Version: v{process.env.REACT_APP_VERSION}
            </span>
          </NavItem>
        </Nav>

        <Nav className="ms-auto" navbar>
          <NavItem>
              <button onClick={handleHomeNavigation} className="menu">
              <FontAwesomeIcon icon={faHome}/>&nbsp;
                Home
              </button>
          </NavItem>
          <NavItem>
              <button onClick={()=>navigate('/news')} className="menu">
              <FontAwesomeIcon icon={faRocket} style={{color: "red"}}/>&nbsp;
                Xeroflex News & Release
              </button>
          </NavItem>
          {/* <NavItem>
            <button onClick={()=>navigate('/docs')}className="menu">
              <FontAwesomeIcon icon={faFile}/>&nbsp;
                Documentation
              </button>
          </NavItem> */}
          <NavItem>
            <button onClick={()=>navigate('/disclaimer')}className="menu">
              <FontAwesomeIcon icon={faExclamationCircle}/>&nbsp;
                Disclaimer & User Responsiblity
              </button>
          </NavItem>
          {/* Account menu item */}
          <NavItem>
            <Account />
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default Header;
