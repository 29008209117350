import React, { useState, useEffect } from 'react';
import { Card, CardBody, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const XeroliteFrame = ({ title, url }) => {
  const [isLoading, setIsLoading] = useState(true);  // Start loading as true
  const [hasError, setHasError] = useState(false);   // State to manage errors

  // Triggered when iframe successfully loads
  const handleLoad = () => {
    setIsLoading(false);  // Set loading to false on successful load
    setHasError(false);   // Reset error state
  };

  // Triggered when iframe fails to load
  const handleError = () => {
    setIsLoading(false);  // Set loading to false on error
    setHasError(true);    // Set error state
  };

  // Reset loading state and error state when URL changes
  useEffect(() => {
    setIsLoading(true);   // Set loading to true when URL changes
    setHasError(false);   // Reset error state
  }, [url]);

  return (
    <Card>
      <CardBody>
        {isLoading && (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <FontAwesomeIcon icon={faCircleNotch} spin size="2x" />
            <p>Loading...</p>
          </div>
        )}

        {hasError ? (
          <Alert color="danger">
            Error: The page could not be loaded. Please try again later.
          </Alert>
        ) : (
          <iframe
            title={title}
            src={url}
            width="100%"
            height="800px"
            style={{ border: 'none', display: isLoading ? 'none' : 'block' }}  // Hide iframe when loading
            onLoad={handleLoad}  // Call handleLoad when iframe loads successfully
            onError={handleError} // Call handleError when iframe fails to load
          />
        )}
      </CardBody>
    </Card>
  );
};

export default XeroliteFrame;
