import { UncontrolledTooltip, Badge } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
const IbOrderStatusBadge = ({ status, index, permId=null, error = null }) => {

    let color = 'default';
    let text = status;
    switch (status) {
      case null:
        text = 'Pending';
        color = 'warning';
        break;
      case 'Filled':
        color = 'success';
        break;
      case 'ApiPending':
      case 'PendingSubmit':
      case 'PendingCancel':
        color = 'warning';
        break;
      case 'PreSubmitted':
      case 'Submitted':
        color = 'primary';
        break;
      case 'Cancelled':
      case 'ApiCancelled':
      case 'Error':
      case 'Inactive':
        color = 'danger';
        break;
      case 'SubmitCreate':
        if(error){
          text = 'Error';
          color = 'danger';
        }else if(permId){
          color = 'secondary';
          text = 'Transmitted, not yet acknowleged';
        }
        break;
      default: 
        color = 'secondary';
        break;
    }

    return (
        <>
            {text && 
            <Badge color={color}>{text} &nbsp;
                {error && <>
                    <span id={`tooltip${index}`} style={{ cursor: 'pointer' }} ><FontAwesomeIcon icon={faInfoCircle}/></span>
                    <UncontrolledTooltip
                        placement="top"
                        target={`tooltip${index}`}
                        trigger="hover"
                    >
                        {error}
                    </UncontrolledTooltip>
                </>
                }

            </Badge>}
        </>
    )
};

export default IbOrderStatusBadge;
