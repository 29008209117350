import { useState, useEffect, useCallback } from "react";
import { ButtonGroup, Button, Badge, Card, CardBody, Table, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faRefresh, faTrash } from "@fortawesome/free-solid-svg-icons";
import AlertPopup from "./AlertPopup";
import axios from 'axios';
import IbOrderStatusBadge from "../shared/IbOrderStatusBadge";
import StatusBadge from "../shared/StatusBadge";
import moment from 'moment';
import JsonViewer from "../shared/JsonViewer";

const Alerts = () => {
  const [message, setMessage] = useState(null);
  const [messages, setMessages] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [filter, setFilter] = useState('today');
  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const handleShowPopup = (message) => {
    setMessage(message);
    setShowPopup(true);
  };

  const getDateRange = (filter) => {
    const today = moment().startOf('day');
    switch (filter) {
      case 'today':
        return { startDate: today, endDate: today };
      case 'this-week':
        return { startDate: moment().startOf('week'), endDate: today };
      case 'last-7-days':
        return { startDate: today.clone().subtract(6, 'days'), endDate: today };
      case 'last-15-days':
        return { startDate: today.clone().subtract(14, 'days'), endDate: today };
      case 'last-30-days':
        return { startDate: today.clone().subtract(29, 'days'), endDate: today };
      default:
        return { startDate: today, endDate: today };
    }
  };

  const fetchMessages = useCallback(async () => {
    try {
      setLoading(true);
      const { startDate, endDate } = getDateRange(filter);
      const response = await axios.get('/api/logs', {
        params: {
          startDate: startDate.toISOString(),
          endDate: endDate.endOf('day').toISOString()
        }
      });
      setMessages(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }, [filter]);

  const refresh = async () => {
    try {
      setLoading(true);
      const { startDate, endDate } = getDateRange(filter);
      const response = await axios.get('/api/logs?fetchib=true', {
        params: {
          startDate: startDate.toISOString(),
          endDate: endDate.endOf('day').toISOString()
        }
      });
      setMessages(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMessages();
    const intervalId = setInterval(fetchMessages, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, [fetchMessages]);

  const handleClearLogs = async () => {
    try {
      await axios.delete('/api/logs/clear');
      fetchMessages();
    } catch (error) {
      console.error('Error clearing logs:', error);
    }
  };

  const toggleConfirmModal = () => {
    setConfirmModal(!confirmModal);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <Card>
      <CardBody>
        <div className="d-flex justify-content-end mb-3">
          <div className="ml-auto d-flex">
            <ButtonGroup>
            <Button
              color={filter === 'today' ? 'primary' : 'secondary'}
              onClick={() => setFilter('today')}
              size="sm"
            >
              Today
            </Button>
            &nbsp;
            <Button
              color={filter === 'this-week' ? 'primary' : 'secondary'}
              onClick={() => setFilter('this-week')}
              size="sm"
            >
              This Week
            </Button>
            &nbsp;
            <Button
              color={filter === 'last-7-days' ? 'primary' : 'secondary'}
              onClick={() => setFilter('last-7-days')}
              size="sm"
            >
              Last 7 Days
            </Button>
            &nbsp;
            <Button
              color={filter === 'last-15-days' ? 'primary' : 'secondary'}
              onClick={() => setFilter('last-15-days')}
              size="sm"
            >
              Last 15 Days
            </Button>
            &nbsp;
            <Button
              color={filter === 'last-30-days' ? 'primary' : 'secondary'}
              onClick={() => setFilter('last-30-days')}
              size="sm"
            >
              Last 30 Days
            </Button>
            </ButtonGroup>
            <div className="vr mx-2" /> {/* Vertical divider using Bootstrap */}

            &nbsp;
            <Button
              color="warning"
              onClick={refresh}
              size="sm"
              className="ml-2"
              disabled={loading}
            >
              <FontAwesomeIcon icon={faRefresh} /> Refresh
            </Button>
            &nbsp;
            <Button
              color="danger"
              onClick={toggleConfirmModal}
              size="sm"
              className="ml-2"
            >
              <FontAwesomeIcon icon={faTrash} /> Clear All
            </Button>
          </div>
        </div>
        <p className="mt-3 text-muted">
          <FontAwesomeIcon icon={faCircleInfo}/> &nbsp;Only maximum 30 days of records will be kept
        </p>
        <Table responsive hover>
          <thead>
            <tr>
              <th>Received</th>
              <th>Name</th>
              <th>Message</th>
              <th>Source IP</th>
              <th>Routing Status</th>
              <th>IB Order Status</th>
            </tr>
          </thead>
          <tbody>
            {messages.map((message, index) => (
              <tr
                key={index}
                style={{ cursor: 'pointer' }}
                onClick={() => handleShowPopup(message)}
              >
                <td>{moment(message.receivedDate).local().format('YYYY-MM-DD HH:mm:ss')}</td>
                <th scope="row">{message.alertName}</th>
                <td><JsonViewer jsonString={message.data} /></td>
                <td>
                  {message.sourceIpLabel !== 'Unknown' ?
                    <Badge color="dark">{message.sourceIpLabel}</Badge> :
                    <Badge color="warning">{message.sourceIp}</Badge>}
                </td>
                <td><StatusBadge status={message.status} error={message.error} index={`route-status${index}`} /></td>
                <td>
                  {message.ibOrderId && (
                    <>
                      <IbOrderStatusBadge status={message.ibOrderStatus} error={message.ibOrderError} index={`ib-status${index}`} />
                      <br />
                      <span>Order ID: {message.ibOrderId}</span>
                      {message.permId && <span className="text-muted"> ({message.permId})</span>}
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <AlertPopup show={showPopup} onClose={handleClosePopup} message={message} />
      </CardBody>

      <Modal isOpen={confirmModal} toggle={toggleConfirmModal}>
        <ModalHeader toggle={toggleConfirmModal}>
          <b>Warning: Deleting Alert Log</b>
        </ModalHeader>
        <ModalBody>
          <p>Deleting these logs <b>WILL NOT</b> affect or cancel any existing open orders with your broker.</p>
          <p>All order management must be performed directly through the Trader Workstation (TWS) platform.</p>
          <p>Are you sure you want to proceed with deleting the alert log?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => { handleClearLogs(); toggleConfirmModal(); }}>Yes</Button>{' '}
          <Button color="secondary" onClick={toggleConfirmModal}>No</Button>
        </ModalFooter>
      </Modal>
    </Card>
  );
};

export default Alerts;
