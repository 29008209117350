import React, { useEffect, useState, useContext } from "react";
import { Col, Row, Alert, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { faBookReader, faExclamationTriangle, faInstitution, faServer, faSignal, faUser, faUserMd, faWrench } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Alerts from "../components/dashboard/Alerts";
import IBContract from "../components/dashboard/IBContract";
import SampleRequestCard from "../components/dashboard/SampleRequestCard";
import WebhookURL from "../components/dashboard/WebhookURL";
import XeroliteFrame from "../components/shared/XeroliteFrame";
import WhiteListCard from "../components/dashboard/WhiteListCard";
import AnalyticCard from "../components/shared/AnalyticCard";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import { faExclamation } from "@fortawesome/free-solid-svg-icons/faExclamation";
import AlertMessageForm from "../components/dashboard/AlertMessageForm";
import LicenseInfo from "../components/dashboard/LicenseInfo";
import { LicenseContext } from "../components/shared/LicenseContext";
import LicenseKeyInput from "../components/dashboard/LicenseKeyInput";

const Home = () => {
  const [inspectBean, setInspectionBean] = useState();
  const [whitelist, setWhitelist] = useState([]);
  const [activeTab, setActiveTab] = useState('1');
  const [urlLoading, setUrlLoading] = useState(false); // Initialize with false
  const [apiKey, setApiKey] = useState();
  const { licenseInfo } = useContext(LicenseContext);

  useEffect(() => {
    fetchTWSInfo();
    fetchWhiteList();
    getApiKey(false); // Fetch API key on component mount
    const interval = setInterval(fetchTWSInfo, 30 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const fetchTWSInfo = async () => {
    try {
      const response = await axios.get('/management/ibhealth');
      setInspectionBean(response.data);
    } catch (error) {
      console.error('Error fetching TWS info:', error);
    }
  };

  const fetchWhiteList = async () => {
    try {
      const response = await axios.get('/api/whitelist');
      setWhitelist(response.data);
    } catch (error) {
      console.error('Error fetching whitelist:', error);
    }
  };

  const getApiKey = async (regenerate) => {
    try {
      setUrlLoading(true);
      const response = await axios.get(`/api/webhook/key?regenerate=${regenerate}`);
      setApiKey(response.data);
    } catch (error) {
      console.error('Error fetching API key:', error);
    } finally {
      setUrlLoading(false);
    }
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const goToIBContractTab = () => {
    toggleTab('2'); // Switch to IBContract tab
  };

  const goToIncomingAlertsTab = () => {
    toggleTab('1'); // Switch to Incoming Alerts tab
  };

  const goToPlaceOrderTab = () => {
    toggleTab('3'); // Switch to Place Order Tab
  };

  const isLicenseInfoValid = (info) => {
    return (
        info &&
        info.email &&
        info.app &&
        info.planType
    );
  };

  return (
    <div>
      <Row>
        <Col md={3}>
          <Row>
            <Col md={12}>
              <WebhookURL getApiKey={getApiKey} apiKey={apiKey} loading={urlLoading} />
              <SampleRequestCard onGoToIBContract={goToIBContractTab} onGoToPlaceOrder={goToPlaceOrderTab} />
              <WhiteListCard whitelist={whitelist} />
            </Col>
          </Row>
        </Col>
        <Col md={9} className="analytics">
          <div className="d-flex flex-row justify-content-end p-1">
          </div>
          <Row>
            <Col>
              {isLicenseInfoValid(licenseInfo) ?
                <LicenseInfo />
              : 
                <LicenseKeyInput />
              }
              </Col>
          </Row>

          <Row>
            
            <Col md={3}>
              <AnalyticCard
                label={`TWS`}
                id={'tws-card'}
                color={inspectBean?.tws?.connected ? "success" : "danger"}
                text={inspectBean?.tws?.connected ? "Connected" : "Disconnected"}
                icon={inspectBean?.tws?.connected ? faSignal : faExclamationTriangle}
                tooltip={`Connectivity between Xerolite and Trade Workstation`}
              />
            </Col>

            {!inspectBean?.tws?.connected &&
              <Col>
                <Alert color="danger">
                  Couldn't connect to TWS. Confirm that "Enable ActiveX and Socket Clients" is enabled and connection port configured in "Socket Port"
                  on the TWS "Edit->Global Configuration...->API->Settings" menu is "{inspectBean?.config?.port}"
                </Alert>
              </Col>
            }
            {inspectBean?.tws?.connected &&
              <Col md={3}>
                <AnalyticCard
                  label={`IB Server`}
                  id={'ibServer-card'}
                  color={inspectBean?.ibServer?.connected ? "primary" : "danger"}
                  text={inspectBean?.ibServer?.connected ? "Connected" : "Disconnected"}
                  icon={inspectBean?.ibServer?.connected ? faServer : faExclamation}
                  tooltip={`Connectivity between TraderWorkstation(TWS) and Interactive Broker (IBKR) Server`}
                />
              </Col>
            }

            {(inspectBean?.tws?.connected && inspectBean?.ibAccountNo) && <>
              <Col md={3}>
                <AnalyticCard
                  label={`IB Account No`}
                  id={'ib-acc-no'}
                  color={`warning`}
                  text={inspectBean?.ibAccountNo}
                  icon={faUser}
                />
              </Col>
              <Col md={3}>
                <AnalyticCard
                  label={`IB Account Type`}
                  id={'ib-acc-type'}
                  color={inspectBean?.ibAccountNo?.startsWith('D') ? 'dark' : 'danger'}
                  text={inspectBean?.ibAccountNo?.startsWith('D') ? 'PAPER' : 'LIVE'}
                  icon={faInstitution}
                />
              </Col>
            </>
            }
          </Row>
          <br />
          <Row>
            {(inspectBean?.tws?.connected && !inspectBean?.ibServer?.connected) &&
              <Col>
                <Alert color="danger">
                  Your TWS/IB Gateway has been disconnected from IB servers. This can occur because of an internet connectivity issue, a nightly reset of the IB servers, or a competing session.
                </Alert>
              </Col>
            }
          </Row>
          <Row>
            <Col>
              <Nav tabs className="custom-nav-tabs">
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => { toggleTab('1'); }}
                  >
                    <FontAwesomeIcon icon={faBell} /> Incoming Alerts {process.env.test}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => { toggleTab('2'); }}
                  >
                    <FontAwesomeIcon icon={faWrench} /> Create Your Alert Request
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => { toggleTab('3'); }}
                  >
                    <FontAwesomeIcon icon={faBookReader} /> Place your order
                  </NavLink>
                </NavItem>
                 <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '4' })}
                    onClick={() => { toggleTab('4'); }}
                  >
                    <FontAwesomeIcon icon={faUserMd} /> User Manual
                  </NavLink>
                  
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Alerts />
                </TabPane>
                <TabPane tabId="2">
                  <IBContract />
                </TabPane>
                <TabPane tabId="3">
                  <AlertMessageForm onSuccess={goToIncomingAlertsTab} apiKey={apiKey}/>
                </TabPane>
                <TabPane tabId="4">
                  <XeroliteFrame title="User Manual" url="https://www.xeroflex.com/xerolite-docs/"/>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Home;