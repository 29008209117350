import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const LicenseContext = createContext();

export const LicenseProvider = ({ children }) => {
    const [licenseInfo, setLicenseInfo] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchLicenseInfo();

        const interval = setInterval(fetchLicenseInfo, 60 * 60 * 1000); // trigger every hour

        return () => {
          clearInterval(interval);
        };
    }, []);

    const fetchLicenseInfo = async () => {
        try {
            const response = await axios.get('/api/licenses/info');
            setLicenseInfo(response.data);
        } catch (err) {
            setError('Failed to fetch license info');
            console.error(err);
        }
    };

    return (
        <LicenseContext.Provider value={{ licenseInfo, setLicenseInfo, error }}>
            {children}
        </LicenseContext.Provider>
    );
};
