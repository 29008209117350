import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from '../views/Home';
import Config from '../views/Config';
import XeroliteFrame from '../components/shared/XeroliteFrame';
import Landing from '../views/Landing';
import FullLayout from "../layouts/FullLayout";
import Disclaimer from '../views/Disclaimer';
import { LicenseProvider } from '../components/shared/LicenseContext';

const isAuthenticated = () => {
  return (
    localStorage.getItem('authToken') !== null ||
    sessionStorage.getItem('authToken') !== null
  );
};

const ProtectedRoute = ({ children }) => {
  if (!isAuthenticated()) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="login" element={<Landing />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <LicenseProvider>
                <FullLayout />
              </LicenseProvider>
            </ProtectedRoute>
          }
        >
          <Route index element={<Home />} />
          <Route path="config" element={<Config />} />
          <Route path="docs" element={<XeroliteFrame title="User Manual" url="https://www.xeroflex.com/xerolite-docs/" />} />
          <Route path="news" element={<XeroliteFrame title="Xeroflex News" url="https://www.xeroflex.com/xeroflex-news/" />} />
          <Route path="disclaimer" element={<Disclaimer/>} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;