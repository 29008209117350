import { Card, CardBody, CardTitle, Container } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from "@fortawesome/free-solid-svg-icons";

const Error = ({ error }) => {
  return (
    <div className="contentArea">
      <Container className="p-4 wrapper" fluid>
        <Card>
          <CardBody>
            <CardTitle tag="h5"><FontAwesomeIcon icon={faBell} /> Error</CardTitle>
            <div>{error}</div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default Error;
