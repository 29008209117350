import { faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Footer = () => {
  return (
    <footer className="footer mt-auto px-4 py-3 d-flex justify-content-between align-items-center">
      <span>
        Powered by <a href="https://www.xeroflex.com" target="_blank" rel="noopener noreferrer">Xeroflex</a> @2024
      </span>
      <div>
        <a href="mailto:info@xeroflex.com" target="_blank" rel="noreferrer" className="me-3">
        <FontAwesomeIcon icon={faEnvelope} /> Email: info@xeroflex.com
        </a>
        <a href="https://www.facebook.com/xeroflex" target="_blank" rel="noopener noreferrer" className="me-3">
          <FontAwesomeIcon icon={faFacebook} /> Facebook
        </a>
        <a href="https://www.instagram.com/xeroflex_trading" target="_blank" rel="noopener noreferrer" className="me-3">
          <FontAwesomeIcon icon={faInstagram} /> Instagram
        </a>
        <a href="https://www.youtube.com/@xeroflex_trading" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faYoutube} /> YouTube
        </a>
      </div>
    </footer>
  );
};

export default Footer;
