import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardBody, CardHeader, Button, Form, Input, Label } from 'reactstrap';
import Popup from '../components/shared/Popup';
import { useNavigate } from 'react-router-dom';

const Config = () => {
    const [setting, setSetting] = useState(null);
    const [value, setValue] = useState("");
    const [message, setMessage] = useState(null);
    const [showPopup, setShowPopup] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
      fetchData();
    }, []);

    const fetchData = async () => {
      try {
        const response = await axios.get('/api/settings?code=RECIPIENT_KEY');
        console.warn("response", response);
        let currentSetting = response.data;
        console.log("currentSetting", currentSetting);
        if (! (currentSetting && currentSetting.data)) {
          currentSetting = {
            code: 'RECIPIENT_KEY',
            label: 'Recipient Key',
            data: ''
          };
        }

        setSetting(currentSetting);
        
        setValue(currentSetting.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const handleChange = (e) => {
      setValue(e.target.value);
    };

    const handleSubmit = (e) => {
      e.preventDefault();

      if (value) {
        setting.data = value;

        if (setting.id) {
          axios.put('/api/settings/' + setting.id, setting)
          .then(response => {
            console.log('Data updated successfully:', response.data);
            handleRefresh();
          })
          .catch(error => {
            console.error('Error updating data:', error);
            handleShowPopup('Fail to configure recipient key. error: ' + error);
          });
        } else {
          axios.post('/api/settings', setting)
          .then(response => {
            console.log('Data created successfully:', response.data);
            handleRefresh();
          })
          .catch(error => {
            console.error('Error creating data:', error);
            handleShowPopup('Fail to configure recipient key. error: ' + error);
          });
        }
      }
    };

    const handleShowPopup = (message) => {
      setMessage(message);
      setShowPopup(true);
    };

    const handleClosePopup = () => {
      setShowPopup(false);
    };

    const handleRefresh = () => {
      window.location.href = '/';
    };

    return (
      <>
        <Card>
          <CardHeader>Configuration</CardHeader>
          <CardBody>
            <div>
              
              <Form onSubmit={handleSubmit}>
              <Label>Recipient Key</Label> 
                <Input
                  type="text"
                  name="value"
                  value={value}
                  onChange={handleChange}
                  maxLength={100}
                  style={{ width: '600px' }}
                  required
                />
                <br/>
                <Button color="primary" type="submit">Save</Button> &nbsp;
                <Button color="secondary" type="button" onClick={()=>navigate("/")}>Cancel</Button>
              </Form>
            </div>
            <Popup show={showPopup} onClose={handleClosePopup} message={message} />
          </CardBody>
        </Card>
      </>
    );
  }

  export default Config;