import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Card, CardBody, Col, Form, Input, Label, Row } from 'reactstrap';
import "../../assets/scss/login.scss";
import Logo from "../../assets/images/xerolite-black-full.png";

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const envUsername = process.env.REACT_APP_USERNAME;
    const envPassword = process.env.REACT_APP_PASSWORD;
    setUsername(envUsername || '');
    setPassword(envPassword || '');
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();

    axios.post('/api/authenticate', {
      username: username,
      password: password
    })
    .then(response => {
      if (response.status === 200) {
        const token = 'logged_in';
        if (rememberMe) {
          localStorage.setItem('authToken', token);
        } else {
          sessionStorage.setItem('authToken', token);
        }

        navigate('/');
      } else {
        setLoginError('Authentication failed. Please try again.');
      }
    })
    .catch(error => {
      console.error('Login failed:', error);
      setLoginError('An error occurred during login.');
    });
  };

  return (
    <Card className="p-4 login">
      <CardBody className="login-card-body">
        <Form onSubmit={handleLogin}>
          <Row>
            <Col>
              <img src={Logo} alt="Logo" width="300"></img>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              {loginError && (
                <Alert color="danger">
                  <strong>Failed to sign in!</strong> <br/>Please check your credentials and try again.
                </Alert>
              )}
            </Col>
            <Col md="12">
              <div class="form-group">
                <Label>Username</Label>
                <Input
                  type="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div class="form-group">
                <Label>Password</Label>
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div class="form-group">
                <Input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                &nbsp;&nbsp;
                <span>Remember Me</span>
              </div>
            </Col>
          </Row>
          <br/>
          <div className="sign-in">
            <Button color='danger' type="submit">
              Sign in
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default LoginForm;
