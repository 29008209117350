import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Input, Card, CardBody, CardSubtitle, Alert } from 'reactstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const AlertMessageForm = ({ onSuccess, apiKey }) => {
  const [message, setMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertColor, setAlertColor] = useState('success');
  const [webhookUrl, setWebhookUrl] = useState('');

  useEffect(() => {
    const fetchWebhookUrl = async () => {
      try {
        const response = await axios.get('/api/webhook/path');
        setWebhookUrl(response.data + apiKey);
      } catch (error) {
        console.error('Error fetching webhook URL:', error);
      }
    };

    fetchWebhookUrl();
  }, [apiKey]);

  const validateMessage = () => {
    let errorMsg = null;
    try {
      
      const json = JSON.parse(message);
      const requiredFields = ["name", "con_id", "sec_type", "exch", "action", "volume"];
      const actionOptions = ["buy", "sell"];
      const secTypeOptions = ["STK", "CASH", "OPT", "IND", "FUT", "CMDTY", "FOP"];

      for (let field of requiredFields) {
        if (!json.hasOwnProperty(field)) {
          errorMsg = `Missing field: ${field}`;
        }
      }

      if (!actionOptions.includes(json.action)) {
        errorMsg = "Invalid action. Must be 'buy' or 'sell'.";
      }

      if (!secTypeOptions.includes(json.sec_type)) {
        errorMsg = `Invalid sec_type. Must be one of ${secTypeOptions.join(", ")}.`;
      }

      if (isNaN(Number(json.con_id))) {
        errorMsg = "con_id must be a valid number.";
      }
      if (isNaN(Number(json.volume))) {
        errorMsg = "volume must be a valid number.";
      }
      
    } catch (e) {
      console.error(e);
      errorMsg = e;
    }

    if(errorMsg != null){
      setAlertMessage(errorMsg);
      setAlertColor('danger');
      setShowAlert(true);
    }else{
      setAlertMessage("Message is validated");
      setAlertColor("success");
      setShowAlert(true);
    }
    return errorMsg;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationError = validateMessage();

    if (validationError) {
      return;
    }
    try {
      const response = await axios.post(webhookUrl, message, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setAlertMessage('Order placed successfully! Click here to view details.');
        setAlertColor('success');
      }
    } catch (error) {
      setAlertMessage('Error placing order. Please try again.');
      setAlertColor('danger');
    }

    setShowAlert(true);
  };  

  const handleBeautifyJson = (m) => {
    try {
      const formattedJson = JSON.stringify(JSON.parse(m), null, 2);
      setMessage(formattedJson);
    } catch (e) {
      setAlertMessage('Invalid JSON format');
      setAlertColor('danger');
      setShowAlert(true);
    }
  };

  const onMessageChange = (e) => {
    setAlertMessage();
    setAlertColor();
    setShowAlert(false);
    setMessage(e.target.value);
    handleBeautifyJson(e.target.value);
  }

  return (
    <Card>
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <CardSubtitle>Submit your alert message to place an order via Xerolite.</CardSubtitle>
          <p className="mt-3 text-muted">
            <FontAwesomeIcon icon={faCircleInfo}/> &nbsp;For security purposes, this feature is only enabled when accessed from localhost. It is designed to mimic incoming alerts from TradingView and other similar trading alert platforms. Please use it carefully.
          </p>
          <FormGroup>
            <Input
              type="textarea"
              name="message"
              id="message"
              value={message}
              onChange={onMessageChange}
              placeholder='Put your alert message in json format here..'
              rows="10"
              required
            />
          </FormGroup>
          <Button type="submit" color="primary">Submit</Button>{' '}
          <Button type="button" color="warning" onClick={validateMessage}>Validate Message</Button>
        </Form>
        {showAlert && (
          <Alert color={alertColor} className="mt-3">
            {alertColor === 'success' ? (
              <Button color="link" onClick={onSuccess} className="alert-link">{alertMessage}</Button>
            ) : (
              alertMessage
            )}
          </Alert>
        )}
      </CardBody>
    </Card>
  );
};

export default AlertMessageForm;
