import React from 'react';
import { Container, Row } from 'reactstrap';
import LoginForm from '../components/login/LoginForm';

const Landing = () => {
  return (
    <Container>
      <Row className="justify-content-center">
        <div className="login-page">
          <LoginForm />
        </div>
      </Row>
    </Container>
  );
};

export default Landing;
