import React, { useState } from 'react';
import { Badge, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const RecommendedBadge = ({ index }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const togglePopover = () => setPopoverOpen(!popoverOpen);

  return (
    <>
      <Badge color="success" id={`badge-rcmd-${index}`}>
      <FontAwesomeIcon icon={faCheck} /> &nbsp;
        RECOMMENDED &nbsp;
        <span
          id={`popover-rcmd-${index}`}
          style={{ cursor: 'pointer' }}
          onClick={togglePopover}
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </span>
      </Badge>
      <Popover
        placement="bottom"
        isOpen={popoverOpen}
        target={`popover-rcmd-${index}`}
        toggle={togglePopover}
      >
        <PopoverHeader>Using SMART routing in the TWS API is recommended because of several key advantages</PopoverHeader>
        <PopoverBody>
          <div>
            <strong>Best Execution:</strong>
            <ul>
              <li><strong>Price Improvement:</strong> Seeks the best price by comparing multiple exchanges.</li>
              <li><strong>Speed:</strong> Quickly finds the optimal route for faster execution.</li>
            </ul>
            <strong>Efficiency:</strong>
            <ul>
              <li><strong>Simplicity:</strong> Eliminates the need to manually select exchanges.</li>
              <li><strong>Automatic Optimization:</strong> Dynamically routes orders to the best market.</li>
            </ul>
            <strong>Market Access:</strong>
            <ul>
              <li><strong>Broader Coverage:</strong> Accesses multiple exchanges and liquidity providers.</li>
              <li><strong>Adaptive Routing:</strong> Adapts to market conditions for optimal execution.</li>
            </ul>
            <strong>Cost Considerations:</strong>
            <ul>
              <li><strong>Reduced Fees:</strong> Minimizes costs by choosing venues with lower fees.</li>
              <li><strong>Fee Transparency:</strong> Avoids complex fee structures.</li>
            </ul>
            <strong>Regulatory Compliance:</strong>
            <ul>
              <li><strong>Best Execution Obligations:</strong> Helps meet regulatory requirements for order execution.</li>
            </ul>
          </div>
        </PopoverBody>
      </Popover>
    </>
  );
};

export default RecommendedBadge;
