import { useState } from 'react';
import axios from 'axios';
// import Popup from '../shared/Popup';
import { Button, Card, CardBody, CardHeader, Input, Row, Col, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

function LicenseKeyInput() {
  const [licenseKey, setLicenseKey] = useState('');
  const [message, setMessage] = useState(null);
  // const [showPopup, setShowPopup] = useState(false);

  const handleLicenseChange = (e) => {
    setLicenseKey(e.target.value);
  };

  const activate = async () => {
    if (!licenseKey) return;

    try {
      const response = await axios.post('/api/licenses/activate', null, {
        params: {
          licenseKey: licenseKey,
        },
      });

      if (response.data.status === 'Active') {
        handleRefresh();
      } else {
        const error = response.data.status;
        console.error('Error activate license key:', "key is " + error);
        setMessage('Failed to activate license key. error: Key is ' + error);
        // handleShowPopup('Fail to activate license key. error: ' + error);
      }
    } catch (error) {
      console.error('Error activate license key:', error);
      setMessage('Failed to activate license key. error: ' + error);
      // handleShowPopup('Fail to activate license key. error: ' + error);
    }
  };

  // const handleShowPopup = (message) => {
  //   setMessage(message);
  //   setShowPopup(true);
  // };

  // const handleClosePopup = () => {
  //   setShowPopup(false);
  // };

  const handleRefresh = () => {
    window.location.href = '/';
  };

  return (
    <Card style={{border: "1px", borderColor: "light-grey", borderStyle:"solid"}}>
      <CardHeader>
        <FontAwesomeIcon icon={faInfoCircle} /> &nbsp;License Key
      </CardHeader>
      <CardBody>
      {/* <Alert color="warning">
          Please enter your license key.
        </Alert> */}
        {message && <Alert color="danger">{message}</Alert>}
        <Row className="license-input">
          <Col xs="9">
            <Input
              type="text"
              value={licenseKey}
              onChange={handleLicenseChange}
              placeholder="Enter license key"
            />
          </Col>
          <Col xs="3">
            <Button color="warning" onClick={activate} block>
              Activate
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default LicenseKeyInput;
