import { useContext, useState } from 'react';
import { Card, CardBody, Alert, Button, Modal, ModalBody, Row, Col } from 'reactstrap';
import { LicenseContext } from '../shared/LicenseContext';
import LicenseKeyInput from './LicenseKeyInput';
import moment from 'moment';

function LicenseInfo() {
  const { licenseInfo, error } = useContext(LicenseContext);
  const [modal, setModal] = useState(false);

  const isLicenseInfoValid = (info) => {
    return info && info.email && info.app && info.planType;
  };

  const formatExpiryDate = (expiryDate) => {
    if (!expiryDate) return 'Lifetime License';

    const now = moment();
    const expiry = moment(expiryDate);
    const diffInDays = expiry.diff(now, 'days');
    const diffInMinutes = expiry.diff(now, 'minutes') % 60;

    if (diffInDays > 0) return `Expires in ${diffInDays} days`;
    if (diffInMinutes > 0) return `Expires in ${diffInMinutes} minutes`;

    return 'Expired';
  };

  const getStatusColor = (status) => {
    return status === 'Active' ? 'green' : 'red'; // Set green for 'Active', red for others
  };

  const toggleModal = () => setModal(!modal);

  return (
    <Card>
      <CardBody>
        {error && <Alert color="danger">{error}</Alert>}
        {licenseInfo && licenseInfo.errorMessage && <Alert color="danger">{licenseInfo.errorMessage}</Alert>}
        {isLicenseInfoValid(licenseInfo) && (
          <Row className="align-items-center">
            <Col>
              <strong>Name:</strong> {licenseInfo.firstName} {licenseInfo.lastName} 
              {/* {licenseInfo.loginName &&<><br/> <strong>UserName:</strong> {licenseInfo.loginName} </>} */}
            </Col>
            {/* <Col><  strong>Email:</strong> {licenseInfo.email}</Col> */}
            <Col><strong>Email:</strong> {licenseInfo.email}<br/>
            {/* <strong>Plan Type:</strong> {licenseInfo.planType} */}
            </Col>
            <Col sm="4">
              <strong>License Status: </strong>
              <span style={{ color: getStatusColor(licenseInfo.status) }}><b>{licenseInfo.status}</b></span> &nbsp;
              <span style={{ color: "blue"}}>({formatExpiryDate(licenseInfo.expiryDate)})</span>
            </Col>
            <Col>
              <Button color="info" onClick={toggleModal}>
                Update License Key
              </Button>
            </Col>
          </Row>
        )}
        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalBody>
            <LicenseKeyInput />
          </ModalBody>
        </Modal>
      </CardBody>
    </Card>
  );
}

export default LicenseInfo;
