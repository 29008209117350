// CopyButton.js
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Button} from 'reactstrap';

const CopyButton = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
    //   onCopySuccess();
      setTimeout(() => setCopied(false), 2000); // Reset state after 2 seconds
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <Button
      color="primary"
      onClick={handleCopy}
    >
      <FontAwesomeIcon icon={copied ? faCheck : faCopy} /> &nbsp;
      {copied ? ' Copied' : ' Copy'}
    </Button>
  );
};

export default CopyButton;
