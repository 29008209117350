import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

function Account() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
      setIsOpen(!isOpen);
  };

  const signOut = () => {
    const tokenKey = 'authToken';

    localStorage.removeItem(tokenKey);
    sessionStorage.removeItem(tokenKey);

    window.location.href = '/';
  }

  return (
    <div className="account">
      <button onClick={toggleDropdown} className="dropdown-toggle">
        <FontAwesomeIcon icon={faUser}/>&nbsp;
        Account
      </button>
      {isOpen && (
        <ul className="dropdown-menu">
          <li onClick={signOut}>Sign Out</li>
        </ul>
      )}
    </div>
  );
}

export default Account;