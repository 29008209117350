import { faListCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Card, Alert, CardBody, CardHeader, Row, Col, CardSubtitle, ListGroup, ListGroupItem } from 'reactstrap';

const WhiteListCard = ({ whitelist }) => {
  return (
    <Card color="">
      <CardHeader> <FontAwesomeIcon icon={faListCheck} /> &nbsp; WhiteList IP</CardHeader>
      <CardBody>
      <Alert color="info">
          Only requests originating from the following whitelisted IP addresses will be accepted for security purposes
          </Alert>
        <ListGroup flush className="">
          <ListGroupItem
            key={-1}
          >
            <Row>
              <Col md="7">
                <CardSubtitle>IP Address</CardSubtitle>
              </Col>
              <Col md="5">
                <CardSubtitle>Label</CardSubtitle>
              </Col>
            </Row>
          </ListGroupItem>
          {whitelist &&
            whitelist.map((item, index) => (
              <ListGroupItem
                key={index}
              >
                <Row>
                  <Col md="7">
                    {item.ip}
                  </Col>
                  <Col md="5">
                    {item.label}
                  </Col>
                </Row>
              </ListGroupItem>
            ))}
        </ListGroup>
      </CardBody>
    </Card>
  );
};

export default WhiteListCard;
